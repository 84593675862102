import * as React from 'react'
import { Helmet } from 'react-helmet'

const Wrapper = ({ children }) => <React.Fragment>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Smilee docs</title>
        <link rel="icon"
            type="image/png"
              href="https://storage.googleapis.com/smilee-static-assets/logo/96.png"
        />
    </Helmet>
    {children}
</React.Fragment>
export default Wrapper
